<template>
  <section ref="main" class="app-home">
    <LazyModuleHomeScreen1 v-if="moduleHomeOneData" v-bind="moduleHomeOneData" />
    <LazyModuleHomeScreen2
      v-if="moduleHomeTwoData"
      :title="moduleHomeTwoData.title"
      :list="moduleHomeTwoData.news_banners"
    />
    <LazyModuleHomeScreen3
      v-if="moduleHomeThreeData"
      :name="moduleHomeThreeData.name"
      :title="moduleHomeThreeData.title"
      :subtitle="moduleHomeThreeData.subtitle"
      :images="moduleHomeThreeData.maps"
    />
    <LazyModuleHomeScreen4
      v-if="moduleHomeFourData && moduleHomeFourData.platforms.filter((item:any) => item.status).length >= 1"
      :title="moduleHomeFourData.title"
      :subtitle="moduleHomeFourData.subtitle"
      :cards="moduleHomeFourData.platforms"
    />
  </section>
</template>
<script lang="ts" setup>
useRouteCache((route) => {
  route.setCacheable().setMaxAge(300).addTags(['index-page-cache-tag'])
})
const { fetchPageData, filterModule } = usePageStore()
await fetchPageData('/')
const moduleHomeOneData = filterModule('ModuleHomePageScreen1')
const moduleHomeTwoData = filterModule('ModuleHomePageScreen2')
const moduleHomeThreeData = filterModule('ModuleHomePageScreen3')
const moduleHomeFourData = filterModule('ModuleHomePageScreen4')
</script>
<style lang="scss" scoped>
.app-home {
  background-image: url('@/assets/images/home-bg.png');
  background-size: 100% auto;
  background-position: center top;
}
@media screen and (min-width: 1024px) {
  .app-home {
    padding-top: 80px;
  }
}
@media not all and (min-width: 1024px) {
  .app-home {
    padding-top: 120px;
  }
  @media screen and (orientation: portrait) {
    .app-home {
      background-image: url('@/assets/images/home-bg-mob.jpg');
    }
  }
}
</style>
